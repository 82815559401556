var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upgrade" },
    [
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "right"
              }
            },
            [
              _c(
                "div",
                { staticClass: "upgrade-info-wrap" },
                [
                  _c("h2", { staticClass: "sub-title" }, [_vm._v("申请售后")]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "282px" },
                      attrs: { prop: "afterSaleType" }
                    },
                    [
                      _c("span", [_vm._v("售后类型")]),
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          disabled: "",
                          outlined: "",
                          dense: true,
                          height: 42,
                          value: "升班"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "inline-formitem",
                      attrs: { prop: "oneOrMore" }
                    },
                    [
                      _c("div", [_vm._v("订单类型")]),
                      _c("check-box", {
                        ref: "check",
                        staticStyle: { "margin-left": "-10px" },
                        attrs: {
                          list: [
                            { label: "一升一", value: "0" },
                            { label: "多升一", value: "1" }
                          ],
                          select: _vm.form.oneOrMore
                        },
                        on: {
                          "update:select": [
                            _vm.switchOneOrMore,
                            function($event) {
                              return _vm.$set(_vm.form, "oneOrMore", $event)
                            }
                          ]
                        }
                      })
                    ],
                    1
                  ),
                  _c("order", {
                    attrs: {
                      form: _vm.form,
                      orderTable: _vm.orderTable,
                      orderBtnDisabled: _vm.orderBtnDisabled,
                      orderModalVisible: _vm.orderModalVisible
                    },
                    on: {
                      "update:orderModalVisible": function($event) {
                        _vm.orderModalVisible = $event
                      },
                      "update:order-modal-visible": function($event) {
                        _vm.orderModalVisible = $event
                      },
                      onDeleteRow: _vm.onDeleteRow
                    }
                  }),
                  _c("cashier", { attrs: { form: _vm.form } })
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c("transaction-infomation", {
                attrs: { transactionInfo: _vm.transactionInfo }
              }),
              _c("div", { staticClass: "line" }),
              _c("student-info", {
                attrs: {
                  isCanStudy: _vm.isCanStudy,
                  transactionInfo: _vm.transactionInfo,
                  signUpForm: _vm.signUpForm,
                  phoneList: _vm.phoneList,
                  disabledChangeStuMobile: _vm.disabledChangeStuMobile
                },
                on: { getCustomeruser: _vm.addCustomeruser }
              }),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "goods-info-wrap" }, [
                _c("h2", { staticClass: "sub-title" }, [_vm._v("商品信息")]),
                _c(
                  "section",
                  { staticClass: "goodsInfoBox" },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-size": "14px", color: "#333" } },
                      [_vm._v("报名类型")]
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { gutter: 24 }
                      },
                      [
                        _c(
                          "el-col",
                          { staticStyle: { width: "306px" } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "hide-details": "auto",
                                    disabled: "",
                                    outlined: "",
                                    dense: true,
                                    height: 42,
                                    value: "升班报名"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "goodsRow", attrs: { gutter: 12 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "goodsName" },
                                on: { focus: _vm.onChoiceGoods }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "goodsCol" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "选择商品",
                                        "hide-details": "auto",
                                        readonly: "",
                                        outlined: "",
                                        dense: true,
                                        height: 42
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onChoiceGoods.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.form.goodsName,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "goodsName", $$v)
                                        },
                                        expression: "form.goodsName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("div", [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentGoods.statusOnlineSell == 2,
                                    expression:
                                      "currentGoods.statusOnlineSell == 2"
                                  }
                                ],
                                staticStyle: { "margin-left": "12px" },
                                attrs: { type: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.previewGoods(
                                      _vm.currentGoods.goodsId
                                    )
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "margin-left": "12px",
                                  color: "#F54955"
                                },
                                attrs: {
                                  type: "error",
                                  disabled: !_vm.form.goodsName
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeChoiceGoods(true)
                                  }
                                }
                              },
                              [_vm._v("重置")]
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "goodsRow goodspecRow",
                        attrs: { gutter: 12 }
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _vm.isShowGoods
                              ? _c(
                                  "el-form-item",
                                  { attrs: { prop: "specName" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "goodsCol" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "选择班型",
                                            "hide-details": "auto",
                                            readonly: "",
                                            outlined: "",
                                            dense: true,
                                            height: 42
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onChoiceGoodsSpec.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.form.specName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "specName",
                                                $$v
                                              )
                                            },
                                            expression: "form.specName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.isShowGoods
                          ? _c("el-col", { attrs: { span: 6 } }, [
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "margin-left": "12px",
                                      color: "#F54955"
                                    },
                                    attrs: {
                                      type: "error",
                                      disabled: !_vm.form.specName
                                    },
                                    on: { click: _vm.removeChoiceSpc }
                                  },
                                  [_vm._v("重置")]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.repeat
                          ? _c("el-col", { attrs: { span: 24 } }, [
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("学员已报名该班型，请勿重复报名")
                              ])
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.employmentShow
                      ? [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#333"
                              }
                            },
                            [_vm._v("就业保底底金")]
                          ),
                          _c(
                            "el-row",
                            {
                              staticStyle: { margin: "12px 0" },
                              attrs: { gutter: 8 }
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "jobLowestSalaryUnderCollege"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: "大专以下(元/月)",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32
                                        },
                                        model: {
                                          value:
                                            _vm.form
                                              .jobLowestSalaryUnderCollege,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "jobLowestSalaryUnderCollege",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.jobLowestSalaryUnderCollege"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "jobLowestSalaryUpCollege"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: "大专及大专以上(元/月)",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32
                                        },
                                        model: {
                                          value:
                                            _vm.form.jobLowestSalaryUpCollege,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "jobLowestSalaryUpCollege",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.jobLowestSalaryUpCollege"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.currentGoods.goodsTemplate == _vm.goodsTemplateEnum.cjzh
                      ? _c("el-form-item", { attrs: { prop: "specTable" } }, [
                          _c("div", { staticStyle: { color: "#666" } }, [
                            _c("span", [
                              _vm._v(
                                "商品价格: ¥" +
                                  _vm._s(
                                    (_vm.goodsSpecTable[0] &&
                                      _vm.goodsSpecTable[0].goodsPrice) ||
                                      0.0
                                  )
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.isShowGoods
                      ? _c(
                          "div",
                          { staticClass: "spec-wrap" },
                          [
                            _vm.currentGoods.goodsTemplate !=
                            _vm.goodsTemplateEnum.cjzh
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-bottom": "1px"
                                        },
                                        attrs: {
                                          border: "",
                                          stripe: "",
                                          size: "mini",
                                          "element-loading-text": "拼命加载中",
                                          "header-cell-style": {
                                            background: "#e5f2ff"
                                          },
                                          "header-cell-class-name":
                                            "normal-table-header",
                                          data: _vm.goodsSpecTable
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.goodsSpecTableColumns,
                                          function(item) {
                                            return [
                                              item.prop === "goodsDiscountPrice"
                                                ? _c("el-table-column", {
                                                    key: item.prop,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      prop: item.prop,
                                                      label: item.label
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            return [
                                                              row.goodsDiscountPrice !=
                                                                "" &&
                                                              row.goodsDiscountPrice !=
                                                                "--" &&
                                                              row.goodsDiscountPrice !=
                                                                undefined
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          (
                                                                            row.goodsPrice -
                                                                            row.goodsDiscountPrice
                                                                          ).toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      "0.00"
                                                                    )
                                                                  ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                : _c("el-table-column", {
                                                    key: item.label,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      label: item.label,
                                                      "min-width":
                                                        item.width || "",
                                                      prop: item.prop
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "empty"
                                                                    )(
                                                                      row[
                                                                        item
                                                                          .prop
                                                                      ],
                                                                      "--"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.currentGoods.goodsTemplate ==
                            _vm.goodsTemplateEnum.cjzh
                              ? _c(
                                  "el-form-item",
                                  { attrs: { prop: "specTable" } },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          border: "",
                                          stripe: "",
                                          size: "mini",
                                          "element-loading-text": "拼命加载中",
                                          "row-style": { height: "48px" },
                                          "cell-style": { padding: "0px" },
                                          "header-cell-style": {
                                            background: "#e5f2ff"
                                          },
                                          "highlight-current-row": "",
                                          "header-cell-class-name":
                                            "normal-table-header",
                                          data: _vm.comComGoodsInfoList
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.cjComComGoodsColumns,
                                          function(item) {
                                            return [
                                              _c("el-table-column", {
                                                key: item.label,
                                                attrs: {
                                                  "show-overflow-tooltip": true,
                                                  width: item.width || "120px",
                                                  label: item.label,
                                                  prop: item.prop
                                                }
                                              })
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isShowGoods
                      ? _c(
                          "div",
                          { staticClass: "two-table-wrap" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "choiceClass",
                                attrs: { prop: "choiceClass" }
                              },
                              [
                                _c("selcetionClass", {
                                  ref: "selcetionClass",
                                  attrs: {
                                    mealGroupData: _vm.mealGroupData,
                                    type: _vm.currentGoods.type,
                                    oneItem: _vm.oneItem
                                  },
                                  on: { confirmClass: _vm.confirmClass }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "line" }),
              _c("collection-info", {
                attrs: {
                  discountType: _vm.discountType,
                  discountPrice: _vm.discountPrice,
                  discountInfoList: _vm.discountInfoList,
                  discountNotuse: _vm.discountNotuse,
                  receiveItemData: _vm.receiveItemData,
                  collectionInfo: _vm.collectionInfo,
                  goodsDis: _vm.goodsDis,
                  wxTradeOrderDiscountVOList: _vm.wxTradeOrderDiscountVOList,
                  discountTip: _vm.discountTip,
                  inputDiscountPrice: _vm.inputDiscountPrice,
                  selectDiscountInfo: _vm.selectDiscountInfo,
                  shouldPay: _vm.shouldPay
                },
                on: {
                  "update:discountType": function($event) {
                    _vm.discountType = $event
                  },
                  "update:discount-type": function($event) {
                    _vm.discountType = $event
                  },
                  "update:discountPrice": function($event) {
                    _vm.discountPrice = $event
                  },
                  "update:discount-price": function($event) {
                    _vm.discountPrice = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-btn" },
        [
          _c(
            "el-button",
            { attrs: { size: "middle", gap: 20 }, on: { click: _vm.doBack } },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.currentGoods.goodsId,
                type: "primary",
                size: "middle",
                loading: _vm.submitLoading
              },
              on: { click: _vm.showSureData }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("order-modal", {
        ref: "orderModal",
        attrs: {
          visible: _vm.orderModalVisible,
          aftersaleType: 1,
          orderModalFields: _vm.orderModalFields,
          orderModalItems: _vm.orderModalItems,
          orderModalRules: _vm.orderModalRules,
          selectMode: _vm.form.oneOrMore,
          businessId: _vm.orderTable[0] && _vm.orderTable[0].businessId,
          mainOrder: _vm.orderTable[0]
        },
        on: {
          "update:orderModalItems": function($event) {
            _vm.orderModalItems = $event
          },
          "update:order-modal-items": function($event) {
            _vm.orderModalItems = $event
          },
          "on-cancel": _vm.onOrderModalCancel,
          "on-ok": _vm.onOrderModalOk
        }
      }),
      _c("goods-specs-modal", {
        key: _vm.goodsSpecModalKey,
        attrs: {
          visible: _vm.gsVisible,
          specTypeArr: _vm.specTypeArr,
          specArray: _vm.specArray,
          specCountArr: _vm.specCountArr,
          specPriceArr: _vm.specPriceArr,
          currentGoodsRank: _vm.currentGoodsRank
        },
        on: { "on-cancel": _vm.onGsCancel, onSpecModalOk: _vm.onSpecModalOk }
      }),
      _c("goods-modal", {
        attrs: {
          type: "upgrade",
          visible: _vm.goodsDrawerVisible,
          goodsForm: _vm.goodsForm,
          goodsFormRules: _vm.goodsFormRules,
          goodsFormItems: _vm.goodsFormItems,
          businessId: _vm.businessId,
          selectGoodsType: String(_vm.selectGoodsTypeEnum.qwUpgrade),
          orderTable: _vm.orderTable[0],
          multiChoice: _vm.form.oneOrMore == "1",
          schoolId: _vm.ncDealSchoolId
        },
        on: {
          "update:visible": function($event) {
            _vm.goodsDrawerVisible = $event
          },
          "on-ok": _vm.onGoodsDrawerOk
        }
      }),
      _c("sure", {
        attrs: {
          visible: _vm.sureVisible,
          showTitle: "确认升班申请",
          sureData: _vm.sureData,
          loading: _vm.loading
        },
        on: { "on-cancel": _vm.onSureCancel, "on-ok": _vm.onSureOk }
      }),
      _c("selUser", {
        attrs: {
          visible: _vm.selUserVisible,
          aftersaleType: 2,
          orderModalFields: _vm.selUserFields,
          orderModalItems: _vm.selUser,
          orderModalRules: _vm.selUserRules,
          selectMode: "1"
        },
        on: { "on-cancel": _vm.onSelUserCancel, "on-ok": _vm.onSelUserOk }
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.showDelOrder, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.showDelOrder = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v("删除该售后订单将清空已选升班商品信息，确认是否删除订单")
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDelOrder = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureDelOrder } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.disountsShow,
            title: "不可用优惠",
            width: "480"
          },
          on: {
            "on-cancel": function($event) {
              _vm.disountsShow = false
            }
          }
        },
        [
          _vm._l(_vm.discountNotuse, function(item) {
            return _c("div", { key: item.id, staticClass: "discount-modal" }, [
              _c("div", [_vm._v(_vm._s(item.activityName))]),
              _c("div", [
                _vm._v("优惠¥0.00~ ¥" + _vm._s(item.preferentialAmount) + ".00")
              ]),
              _c("div", [
                _c("div", [_vm._v("学员不符合优惠条件")]),
                _c("div", [_vm._v(_vm._s(item.endTime) + " 过期")])
              ])
            ])
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.disountsShow = false
                    }
                  }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }